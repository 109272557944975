import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Button } from "@mui/material";

export default function ChartsOverviewDemo() {
  const [inventoryData, setInventoryData] = React.useState([]);

  const [filteredMaterials, setFilteredMaterials] = React.useState([]);
  const [newCategory, setNewCategory] = React.useState("");

  const uniqueCategories = [
    ...new Set(inventoryData.map((item) => item.categorie)),
  ];

  const filterItem = (curcat, field) => {
    const newItem = inventoryData.filter((newVal) => {
      return newVal[field] === curcat;
    });

    setFilteredMaterials(newItem);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/material",
          {
            credentials: "include",
          }
        );
        if (response.status === 401 || response.status === 403) {
          throw new Error("Session expired or unauthorized access.");
        }
        if (!response.ok) {
          throw new Error("Failed to fetch materials");
        }
        const fetchedData = await response.json();
        setInventoryData(fetchedData.data);
        setFilteredMaterials(fetchedData.data);
      } catch (error) {
        console.error("Fetching error:", error);
        // Handle the error according to your needs
      }
    };

    fetchData();
  }, []);

  console.log("data", filteredMaterials);
  if (!filteredMaterials.length) {
    return <div>Loading...</div>;
  }

  const series = [
    {
      data: filteredMaterials.map((item) => item.cantitateDepozit),
      label: "Depozit",
    },
    {
      data: filteredMaterials.map((item) => item.cantitateSantier),
      label: "Plantatii",
    },
    {
      data: filteredMaterials.map(
        (item) => item.stoc - item.cantitateSantier - item.cantitateDepozit
      ),
      label: "Nefolosit",
    },
    {
      data: filteredMaterials.map((item) => item.stoc),
      label: "Stoc",
    },
  ];

  const xAxis = [
    {
      data: filteredMaterials.map((item) => item.nume),
      scaleType: "band",
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
      <div>
        <Button
          variant="contained"
          fullWidth
          sx={{
            bgcolor: "grey.dark",
            color: "white", // normal background color
            "&:hover": {
              bgcolor: "grey.light",
              color: "black", // hover background color
            },
          }}
          onClick={() => setFilteredMaterials(inventoryData)}
        >
          Toate
        </Button>
        {uniqueCategories.map((category) => (
          <div style={{ marginTop: "8px" }}>
            <Button
              key={category}
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => filterItem(category, "categorie")}
            >
              {category}
            </Button>
          </div>
        ))}
      </div>
      <BarChart
        series={series}
        height={290}
        xAxis={xAxis}
        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
      />
    </div>
  );
}
