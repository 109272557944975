import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Box, TextField } from "@mui/material";
import { Select, MenuItem, InputLabel } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useLocation } from "react-router-dom";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Record = ({ depozitId, section, triggerPlantatie, setTriggerMutare }) => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");

  const [data, setData] = useState([]);
  const [elimina, setElimina] = useState(false);

  const [desiredMaterial, setDesiredMaterial] = useState();
  const [quantity, setQuantity] = useState("");
  const [trigger, setTrigger] = useState();

  const [openEliminare, setOpenEliminare] = useState(false);
  const [openMutare, setOpenMutare] = useState(false);

  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);
  const [selectedSantier, setSelectedSantier] = useState("Santier");
  const [selectedDepozit, setSelectedDepozit] = useState("Depozit");
  const [selectedButton, setSelectedButton] = useState("depozit");

  const apiUrl =
    section === "depozit"
      ? `https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/depozit/${depozitId}/materialeDepozit`
      : `https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/santier/${depozitId}/materialeSantier`;

  const handleClose = () => {
    setOpenMutare(false);
    setQuantity("");
    setElimina(false);
    setOpenEliminare(false);
  };

  const handleSantierChange = (event) => {
    setSelectedSantier(event.target.value);
  };

  const handleDepozitChange = (event) => {
    setSelectedDepozit(event.target.value);
  };

  const handleButtonChange = (event, newSelectedButton) => {
    console.log(newSelectedButton);
    setSelectedButton(newSelectedButton);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const hanldeMutareDesired = (item) => {
    setDesiredMaterial(item);
    setOpenMutare(true);
  };

  const handleMaterialeMutare = () =>
    section === "santier"
      ? moveMaterialeFromSantier()
      : moveMaterialeFromDepozit();

  const handleEliminaMaterial = (item) => {
    setOpenEliminare(true);
    setDesiredMaterial(item);
    setElimina(true);
  };

  const handleEliminaMaterialDa = (item) => {
    if (section === "santier") {
      moveMaterialeFromSantier();
      setOpenEliminare(false);
    } else {
      moveMaterialeFromDepozit();
      setOpenEliminare(false);
    }
  };

  const errorHandler = () => {
    if (quantity > desiredMaterial?.cantitate) {
      return true;
    }
    return false;
  };

  const moveMaterialFromDepozitToDepozit = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity,
      depozitId,
      depozitIdTo: selectedDepozit,
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/depozit/move-to-depozit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Depozit");
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Depozit");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialFromDepozitToSantier = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity,
      depozitId,
      santierId: selectedSantier,
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/depozit/move-to-santier`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Depozit");
        setOpenMutare(false);
        setTriggerMutare(supplierData);
      } else {
        console.error("Failed to move to Depozit", supplierData);
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialeFromDepozit = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity: elimina ? desiredMaterial.cantitate : quantity,
      depozitId,
      eliminare: elimina,
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/material/move-to-materiale-from-depozit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Santier");
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Santier");
        console.log(supplierData);
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialFromSantierToDepozit = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity,
      depozitId: selectedDepozit,
      santierId: pathSegments[3],
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/move-to-depozit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Depozit");
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Depozit", supplierData);
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialFromSantierToSantier = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity,
      santierId: depozitId,
      santierIdTo: selectedSantier,
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/santier/move-to-santier`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Depozit");
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Depozit", supplierData);
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialeFromSantier = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity: elimina ? desiredMaterial.cantitate : quantity,
      santierId: depozitId,
      eliminare: elimina,
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/material/move-to-materiale-from-santier`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      console.log("gggh", supplierData);

      if (response.ok) {
        console.log("Material moved successfully to Santier");
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Santier");
      }
    } catch (error) {
      console.error("An error occurred", error);
      console.log("gggh", supplierData);
    }
  };

  const fetchData = useCallback(async (api, setter) => {
    try {
      const res = await fetch(api);
      if (res.ok) {
        const data = await res.json();
        setter(data);
      } else {
        console.error("Response not OK:", res);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(apiUrl, setData);
  }, [depozitId, apiUrl, openEliminare, openMutare, trigger]);

  useEffect(() => {
    const fetchSubData = async () => {
      try {
        const depozitsResponse = await fetch(
          "https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/depozit"
        ).then((response) => response.json());
        const santiersResponse = await fetch(
          "https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/santier"
        ).then((response) => response.json());

        setDepozits(depozitsResponse);
        setSantiers(santiersResponse);
      } catch (error) {
        console.log("Error fetching data: ", error);
      }
    };

    fetchSubData();
  }, [openMutare, triggerPlantatie]);

  async function deleteMaterial() {
    let apiMaterial;

    if (section === "santier") {
      apiMaterial = `https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/santier/${depozitId}/${desiredMaterial.materialId}`;
    } else {
      apiMaterial = `https://seal-app-g5l8g.ondigitalocean.app/api/gestiune/depozit/${depozitId}/${desiredMaterial.materialId}`;
    }

    const materialData = {
      quantity: desiredMaterial.cantitate,
      id: desiredMaterial._id,
    };
    await fetch(apiMaterial, {
      method: "DELETE",
      body: JSON.stringify(materialData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  function handleDeleteMaterial() {
    deleteMaterial();
    setOpenEliminare(false);
    setTrigger(desiredMaterial?.nume);
  }

  return (
    <div key={depozitId} style={{ maxHeight: "40vh", overflowY: "auto" }}>
      <table
        className="table table-striped"
        style={{ marginTop: 20, borderSpacing: "50px" }}
      >
        <thead>
          <tr>
            <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>Nume</th>
            <th style={{ paddingRight: "40px" }}>Producator</th>
            <th style={{ paddingRight: "40px" }}>Model</th>
            <th style={{ paddingRight: "40px" }}>
              Cantitate {section === "depozit" ? "Depozit" : "Plantatie"}
            </th>
            <th>Actiuni</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item._id}>
              <td
                style={{
                  paddingRight: "40px",
                  paddingLeft: "30px",
                }}
              >
                {item.nume}
              </td>
              <td style={{ paddingRight: "40px" }}>{item.numeFirma}</td>
              <td style={{ paddingRight: "40px" }}>{item.model}</td>
              <td style={{ paddingRight: "40px" }}>{item.cantitate}</td>
              <td>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "blue.dark", // normal background color
                    "&:hover": {
                      bgcolor: "blue.light", // hover background color
                    },
                  }}
                  onClick={() => hanldeMutareDesired(item)}
                >
                  Muta
                </Button>
                |
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "red.dark", // normal background color
                    "&:hover": {
                      bgcolor: "red.light", // hover background color
                    },
                  }}
                  onClick={() => handleEliminaMaterial(item)}
                >
                  Elimina
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={openMutare}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div key={selectedButton}>
            <h2>{`Muta: ${desiredMaterial?.nume}`}</h2>
            <br />
            <ToggleButtonGroup
              value={selectedButton}
              exclusive
              onChange={handleButtonChange}
            >
              <ToggleButton value="depozit">Depozit</ToggleButton>
              <ToggleButton value="santier">Plantatie</ToggleButton>
              <ToggleButton value="materiale">Depozit prinicpal</ToggleButton>
            </ToggleButtonGroup>
            <br />
            <br />
            {selectedButton === "santier" ? (
              <>
                {" "}
                <div style={{ width: "100%", gap: "5px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Cantitate
                  </InputLabel>
                  <TextField
                    value={quantity}
                    onChange={handleQuantityChange}
                    error={errorHandler()}
                    helperText={errorHandler() ? "Stoc insuficient" : ""}
                    style={{ width: "30%", marginRight: "10px" }}
                  />

                  <Select
                    value={selectedSantier}
                    onChange={handleSantierChange}
                    placeholder="Santier"
                    style={{ width: "30%" }}
                  >
                    {santiers.map((material) => (
                      <MenuItem key={material._id} value={material._id}>
                        {material.nume} - {material.locatie}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    display: " flex",
                    marginTop: "10px",
                    marginRight: "60px",
                    justifyContent: "flex-end",
                  }}
                >
                  {section === "santier" ? (
                    <Button
                      onClick={() => moveMaterialFromSantierToSantier()}
                      variant="contained"
                    >
                      Muta in Plantatie
                    </Button>
                  ) : (
                    <Button
                      onClick={() => moveMaterialFromDepozitToSantier()}
                      variant="contained"
                    >
                      Muta in Plantatie
                    </Button>
                  )}
                </div>
              </>
            ) : selectedButton === "depozit" ? (
              <>
                <div style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Cantitate
                  </InputLabel>
                  <TextField
                    value={quantity}
                    onChange={handleQuantityChange}
                    error={errorHandler()}
                    helperText={errorHandler() ? "Stoc insuficient" : ""}
                    style={{ width: "30%", marginRight: "10px" }}
                  />

                  <Select
                    value={selectedDepozit}
                    onChange={(e) => handleDepozitChange(e)}
                    placeholder="Santier"
                    style={{ width: "30%" }}
                  >
                    {depozits.map((material) => (
                      <MenuItem key={material._id} value={material._id}>
                        {material.nume} - {material.locatie}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    display: " flex",
                    marginTop: "10px",
                    marginRight: "60px",
                    justifyContent: "flex-end",
                  }}
                >
                  {section === "santier" ? (
                    <Button
                      onClick={() => moveMaterialFromSantierToDepozit()}
                      variant="contained"
                    >
                      Muta in Depozit
                    </Button>
                  ) : (
                    <Button
                      onClick={() => moveMaterialFromDepozitToDepozit()}
                      variant="contained"
                    >
                      Muta in Depozit
                    </Button>
                  )}
                </div>
              </>
            ) : selectedButton === "materiale" ? (
              <>
                {" "}
                <div style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Cantitate
                  </InputLabel>
                  <TextField
                    value={quantity}
                    onChange={handleQuantityChange}
                    error={errorHandler()}
                    helperText={errorHandler() ? "Stoc insuficient" : ""}
                    style={{ width: "30%", marginRight: "10px" }}
                  />
                </div>
                <div
                  style={{
                    display: " flex",
                    marginTop: "10px",
                    marginRight: "60px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button onClick={handleMaterialeMutare} variant="contained">
                    Muta Materiale
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEliminare}
        onClose={() => setOpenEliminare(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <b>{desiredMaterial?.nume}</b>
            Sigur elimiati materialul?
            <br />
            <br />
            <span style={{ color: "grey" }}>
              {`
              Eliminandul, materialul va fi sters din ${
                section === "santier" ? "plantatia actuala" : "depozitul actual"
              } cat si
              din ${
                section === "santier"
                  ? "cantitate santier"
                  : "cantitate depozit"
              }  si stocul din depozitul principal.`}
              <br /> Daca doriti sa-l mutati inapoi in depozitul principal fara
              a afecta stocul global, folositi functia de mutare.
            </span>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ marginTop: "8px", marginRight: "12px" }}
                variant="contained"
                sx={{
                  bgcolor: "red.dark", // normal background color
                  "&:hover": {
                    bgcolor: "red.light", // hover background color
                  },
                }}
                onClick={handleDeleteMaterial}
              >
                Elimina
              </Button>

              <br />

              <Button
                style={{ marginTop: "8px" }}
                variant="contained"
                onClick={() => setOpenEliminare(false)}
              >
                Nu
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Record;
