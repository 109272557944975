import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Tooltip, Typography } from "@mui/material";

function formatNumberWithDots(number) {
  const numStr = String(number);
  const dotPosition = numStr.length % 3 || 3;
  let result = numStr.slice(0, dotPosition);
  for (let i = dotPosition; i < numStr.length; i += 3) {
    result += "." + numStr.slice(i, i + 3);
  }
  return result;
}

function formatCustomDateString(inputDateString) {
  const dateObject = new Date(inputDateString);

  const hours = String(dateObject.getHours()).padStart(2, "0");
  const minutes = String(dateObject.getMinutes()).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const year = dateObject.getFullYear();

  return `${hours}:${minutes} ${day}-${month}-${year}`;
}

const styleText = {
  fontSize: 17,
  marginBottom: 4,
};
const DetaliiComanda = () => {
  const params = useParams();
  const [comanda, setComanda] = useState({});

  console.log("yo", params);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `https://seal-app-g5l8g.ondigitalocean.app/api/vanzari/comenzi/${params.id}`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: params.id }),
          }
        );

        if (res.ok) {
          const deCrypt = await res.json();
          setComanda(deCrypt);
        } else {
          console.error("Failed to fetch:", res.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [params.id]);

  return (
    <div key={comanda}>
      <h2>Comanda {comanda.comandaId}</h2>
      <span style={{ marginLeft: 8 }}>
        {formatCustomDateString(comanda.createdAt)}
      </span>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
          <span style={styleText}>
            <b>Client:</b> {comanda.client}
          </span>
          <span style={styleText}>
            <b>CUI:</b> {comanda.cui}
          </span>
          <span style={styleText}>
            <b>Mod plata:</b> {comanda.modPlata}
          </span>
        </div>

        <div
          style={{ display: "flex", flexDirection: "column", paddingRight: 32 }}
        >
          <span style={{ fontSize: 20 }}>Total comanda: {comanda.suma}</span>

          <span style={{ fontSize: 20 }}>
            Platit:{" "}
            {comanda.platit ? (
              <span style={{ color: "green" }}>DA</span>
            ) : (
              <span style={{ color: "red" }}>NU</span>
            )}
          </span>
        </div>
      </div>

      <div style={{ maxHeight: "62vh", overflowY: "auto" }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                Producator
              </th>
              <th style={{ paddingRight: "40px" }}>Model</th>

              <th style={{ paddingRight: "40px" }}>Pret Vanzare</th>
              <th style={{ paddingRight: "40px" }}>Buc</th>

              <th style={{ paddingRight: "40px" }}>TVA</th>
            </tr>
          </thead>
          <tbody>
            {comanda?.produse?.map((item, index) => (
              <tr key={index}>
                <td style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                  <Tooltip
                    title={
                      <Typography
                        variant="body2"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        adaugat de {item.author} la{" "}
                        {formatCustomDateString(item.createdAt)}
                      </Typography>
                    }
                    placement="right"
                  >
                    <span>{item.producator}</span>
                  </Tooltip>
                </td>
                <td style={{ paddingRight: "40px" }}>{item.model}</td>

                <td style={{ paddingRight: "40px" }}>
                  {formatNumberWithDots(item.pretV)}
                </td>
                <td style={{ paddingRight: "40px" }}>{item.buc}</td>
                <td style={{ paddingRight: "40px" }}>{item.tva}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetaliiComanda;
