import React from "react";
import IstoricTabel from "../../../common/Istoric/IstoricTabel";

const istoricVanzari = () => {
  return (
    <IstoricTabel
      url={`https://seal-app-g5l8g.ondigitalocean.app/api/istoric`}
    />
  );
};

export default istoricVanzari;
