import React, { useState, useEffect } from "react";
import ClientiView from "./ClientiView";

const ClientiContainer = () => {
  const [clientiList, setClientiList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [trigger, setTrigger] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://seal-app-g5l8g.ondigitalocean.app/api/soferi/clienti"
        );
        const data = await response.json();
        setClientiList(data);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching clienti:", error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
  }, [trigger]);

  return (
    <div>
      {loading ? (
        // Display loading indicator or message while data is being fetched
        <p>Loading...</p>
      ) : (
        // Render ClientiView with the fetched data
        <ClientiView clienti={clientiList} setTrigger={setTrigger} />
      )}
    </div>
  );
};

export default ClientiContainer;
