import React, { useEffect, useState } from "react";
import { Button, Modal, Box, TextField, Collapse } from "@mui/material";
import {
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  Typography,
  Menu,
} from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Factura from "../../../utils/rapoarte/raportInventarCentralPDF";
import { generateExcelFile } from "./rapoarte/raportXLSX";
import "./listaGestiune.css";
import { useAuth } from "../../common/Login/AuthContext";
import InfoIcon from "@mui/icons-material/Info";
import { useDepozitContext } from "./context/AuthContext";
import InteractiveSVG from "./itemsMap/svgInteractive";
import mapSvg from "../../../images/map1.png";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Link, useNavigate } from "react-router-dom";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { epochFormatDate } from "../../../utils/formats/epochFormat";
import AdaugaMaterialModal from "./modals/AdaugaMaterialModal";
import MutareMaterialModal from "./modals/MutareMaterialModal";
import EditMaterialModal from "./modals/EditeazaMaterialModal";
import DeleteMaterialModal from "./modals/DeleteMaterialModal";

import { useSnackbar } from "notistack";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const NouaLista = () => {
  const { userData } = useAuth();

  const navigate = useNavigate();

  const [openMaterial, setOpenMaterial] = useState(false);
  const [material, setMaterial] = useState([]);
  const [openMutare, setOpenMutare] = useState(false);
  const [materialId, setMaterialId] = useState();

  const [nume, setNume] = useState();
  const [numeFirma, setNumeFirma] = useState();
  const [model, setModel] = useState();
  const [numarInventar, setNumarInventar] = useState();

  const [categorieProdus, setCategorieProdus] = useState();
  const [dataExpirare, setDataExpirare] = useState();
  const [stoc, setStoc] = useState();

  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");

  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);
  const [selectedSantier, setSelectedSantier] = useState("Santier");
  const [selectedDepozit, setSelectedDepozit] = useState("Depozit");
  const [selectedButton, setSelectedButton] = useState("depozit");

  const [openCategoryMobile, setOpenCategoryMobile] = useState(false);
  const [trigger, setTrigger] = useState("");

  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  const [desiredMaterial, setDesiredMaterial] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const uniqueCategories = [...new Set(material.map((item) => item.categorie))];

  const filterItem = (curcat, field) => {
    const newItem = material.filter((newVal) => {
      return newVal[field] === curcat;
    });

    setFilteredMaterials(newItem);
    setOpenCategoryMobile(false);
  };

  const handleClose = () => {
    setOpenMaterial(false);
    setOpenMutare(false);
    setNume("");
    setNumeFirma("");
    setModel("");
    setStoc("");
  };

  const handleSantierChange = (event) => {
    setSelectedSantier(event.target.value);
  };

  const handleDepozitChange = (event) => {
    setSelectedDepozit(event.target.value);
  };

  const handleMaterialChange = (event) => {
    setSelectedMaterial(event.target.value);
    setQuantity("");
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleButtonChange = (event, newSelectedButton) => {
    setSelectedButton(newSelectedButton);
  };

  const handleAllCategory = (material) => {
    setFilteredMaterials(material);
    setOpenCategoryMobile(false);
  };

  const errorHandler = () => {
    return (
      quantity >
      selectedMaterial.stoc -
        selectedMaterial.cantitateDepozit -
        selectedMaterial.cantitateSantier
    );
  };

  function formatNumberWithDots(number) {
    const numStr = String(number);
    const dotPosition = numStr.length % 3 || 3;
    let result = numStr.slice(0, dotPosition);
    for (let i = dotPosition; i < numStr.length; i += 3) {
      result += "." + numStr.slice(i, i + 3);
    }
    return result;
  }

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const currentDate = getCurrentDate();

  function formatCustomDateString(inputDateString) {
    const dateObject = new Date(inputDateString);

    const hours = String(dateObject.getHours()).padStart(2, "0");
    const minutes = String(dateObject.getMinutes()).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = dateObject.getFullYear();

    return `${hours}:${minutes} ${day}-${month}-${year}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [materialResponse] = await Promise.all([
          fetch(
            "https://seal-app-g5l8g.ondigitalocean.app/api/vanzari/produse",
            {
              credentials: "include",
            }
          ).then((response) => {
            if (response.status === 401 || response.status === 403) {
              throw new Error("Session expired or unauthorized access.");
            }
            if (!response.ok) throw new Error("Failed to fetch materials");
            return response.json();
          }),
        ]);

        setMaterial(materialResponse.data); // Assuming the response has a data property
        setFilteredMaterials(materialResponse.data); // Assuming the response has a data property
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.message === "Session expired or unauthorized access.") {
          alert(error.message);
          navigate("/login");
        }
      }
    };

    fetchData();
  }, [trigger, openMaterial, openMutare]); // Add navigate to dependencies if it's not causing re-renders

  useEffect(() => {
    setMaterialId(selectedMaterial._id);
  }, [selectedMaterial]);

  //---------------Edit feature-------------

  const [openEdit, setOpenEdit] = useState(false);
  const [formEdit, setFormEdit] = useState({});

  const handleEditForm = (item) => {
    setFormEdit(item);
    setOpenEdit(true);
  };

  function updateForm(value) {
    return setFormEdit((prev) => {
      return { ...prev, ...value };
    });
  }

  return (
    <>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h3>Lista vanzare</h3>
            <span>Vanzari: {material.length}</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <PDFDownloadLink
                document={
                  <Factura
                    materiale={material}
                    section={"inventar"}
                    date={currentDate}
                  />
                }
                fileName={`${"Raport "}${"inventar"}${" "}${currentDate}${".pdf"}`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Generating PDF..."
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: "blue.focus", // normal background color
                        "&:hover": {
                          bgcolor: "blue.focusHover", // hover background color
                        },
                      }}
                    >
                      {" "}
                      Genereaza Raport Inventar
                    </Button>
                  )
                }
              </PDFDownloadLink>{" "}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "8px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  bgcolor: "green.light", // normal background color
                  "&:hover": {
                    bgcolor: "green.dark", // hover background color
                  },
                }}
                onClick={() => generateExcelFile(material, currentDate)}
              >
                Genereaza Raport XLSX
              </Button>
            </div>
            {/* <Button
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
              onClick={() => setOpenMaterial(true)}
            >
              Adauga Material
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
              onClick={() => setOpenMutare(!openMutare)}
            >
              Muta Materiale
            </Button> */}

            {/* <TextField
              type="text"
              placeholder="Cauta dupa nume..."
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            /> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="desktop-grid-agro">
        <div className="desktop-agro">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span style={{ fontWeight: 500, fontSize: "18px" }}>Categorii</span>
          </div>

          <div style={{ marginTop: "8px" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => setFilteredMaterials(material)}
            >
              Toate
            </Button>
          </div>

          {uniqueCategories.map((category) => (
            <div style={{ marginTop: "8px" }}>
              <Button
                key={category}
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: "grey.dark",
                  color: "white", // normal background color
                  "&:hover": {
                    bgcolor: "grey.light",
                    color: "black", // hover background color
                  },
                }}
                onClick={() => filterItem(category, "categorie")}
              >
                {category}
              </Button>
            </div>
          ))}
        </div>
        <div>
          <div className="mobile-agro">
            <Button
              variant="contained"
              sx={{
                bgcolor: "grey.dark",
                color: "white", // normal background color
                "&:hover": {
                  bgcolor: "grey.light",
                  color: "black", // hover background color
                },
              }}
              onClick={() => setOpenCategoryMobile(!openCategoryMobile)}
            >
              Categorii
            </Button>
            <Collapse in={openCategoryMobile}>
              <div
                style={{
                  borderRight: "1px solid #ccc",
                  padding: "12px",
                  borderRadius: "4px",
                  marginRight: "4px",
                }}
              >
                <div style={{ marginTop: "8px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "grey.dark",
                      color: "white", // normal background color
                      "&:hover": {
                        bgcolor: "grey.light",
                        color: "black", // hover background color
                      },
                      width: "20%",
                    }}
                    onClick={() => handleAllCategory(material)}
                  >
                    Toate
                  </Button>
                </div>

                {uniqueCategories.map((category) => (
                  <div style={{ marginTop: "8px" }}>
                    <Button
                      key={category}
                      variant="contained"
                      fullWidth
                      sx={{
                        bgcolor: "grey.dark",
                        color: "white", // normal background color
                        "&:hover": {
                          bgcolor: "grey.light",
                          color: "black", // hover background color
                        },
                        width: "20%",
                      }}
                      onClick={() => filterItem(category, "categorie")}
                    >
                      {category}
                    </Button>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
          <div style={{ overflowY: "auto" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                    Producator
                  </th>
                  <th style={{ paddingRight: "40px" }}>Model</th>
                  <th style={{ paddingRight: "40px" }}>Categorie</th>
                  <th style={{ paddingRight: "40px" }}>Numar inventar</th>
                  <th style={{ paddingRight: "40px" }}>Serie S/N</th>
                  <th style={{ paddingRight: "40px" }}>Bucati</th>
                  <th style={{ paddingRight: "40px" }}>Pret Achizitie</th>
                  <th style={{ paddingRight: "40px" }}>Pret Vanzare</th>
                  <th style={{ paddingRight: "40px" }}>Comanda ID</th>
                </tr>
              </thead>
              <tbody>
                {filteredMaterials.map((item, index) => (
                  <tr key={index}>
                    <td style={{ paddingRight: "40px", paddingLeft: "30px" }}>
                      <Tooltip
                        title={
                          <Typography
                            variant="body2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            adaugat de {item.author} la{" "}
                            {formatCustomDateString(item.createdAt)}
                          </Typography>
                        }
                        placement="right"
                      >
                        <span>{item.producator}</span>
                      </Tooltip>
                    </td>
                    <td style={{ paddingRight: "40px" }}>{item.model}</td>
                    <td style={{ paddingRight: "40px" }}>{item.categorie}</td>
                    <td style={{ paddingRight: "40px" }}>
                      {item.numarInventar}
                    </td>

                    <td style={{ paddingRight: "40px" }}>{item.serieSN}</td>
                    <td style={{ paddingRight: "40px" }}>{item.buc}</td>

                    <td style={{ paddingRight: "40px" }}>
                      {formatNumberWithDots(item?.pretA)}
                    </td>

                    <td style={{ paddingRight: "40px" }}>
                      {formatNumberWithDots(item.pretV)}
                    </td>
                    <td
                      style={{
                        paddingRight: "40px",

                        textDecoration: "underline",
                      }}
                    >
                      <Link
                        style={{ color: "black" }}
                        to={`https://blockarch.smarty-erp.ro/vanzari/comenzi/${item.comandaSubId}`}
                      >
                        {item.comandaId}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default NouaLista;
