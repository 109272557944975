import React, { useEffect, useState } from "react";
import UserManagementView from "./UserManagementView";

const UserManagementContainer = () => {
  const [users, setUsers] = useState([]);
  const [trigger, setTrigger] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          "https://seal-app-g5l8g.ondigitalocean.app/api/users",
          {
            credentials: "include",
          }
        );
        if (res.ok) {
          const data = await res.json();
          setUsers(data);
        } else {
          console.error("Response not OK:", res);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [trigger]);

  return <UserManagementView users={users} setTrigger={setTrigger} />;
};

export default UserManagementContainer;
