import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Box, TextField } from "@mui/material";
import { Select, MenuItem, InputLabel, Menu } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../common/Login/AuthContext";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { epochFormatDate } from "../../../utils/formats/epochFormat";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Record = ({ depozitId, section, triggerPlantatie, setTriggerMutare }) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const pathSegments = location.pathname.split("/");

  const { userData } = useAuth();

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [elimina, setElimina] = useState(false);

  const [desiredMaterial, setDesiredMaterial] = useState();
  const [quantity, setQuantity] = useState("");
  const [trigger, setTrigger] = useState();

  const [openEliminare, setOpenEliminare] = useState(false);
  const [openMutare, setOpenMutare] = useState(false);

  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);
  const [selectedSantier, setSelectedSantier] = useState("Santier");
  const [selectedDepozit, setSelectedDepozit] = useState("Depozit");
  const [selectedButton, setSelectedButton] = useState("depozit");

  const apiUrl =
    section === "depozit"
      ? `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/depozit/${depozitId}/materialeDepozit`
      : `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/santier/${depozitId}/materialeSantier`;

  const handleClose = () => {
    setOpenMutare(false);
    setQuantity("");
    setElimina(false);
    setOpenEliminare(false);
  };

  const handleSantierChange = (event) => {
    setSelectedSantier(event.target.value);
  };

  const handleDepozitChange = (event) => {
    setSelectedDepozit(event.target.value);
  };

  const handleButtonChange = (event, newSelectedButton) => {
    console.log(newSelectedButton);
    setSelectedButton(newSelectedButton);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const hanldeMutareDesired = (item) => {
    setDesiredMaterial(item);
    setOpenMutare(true);
  };

  const handleMaterialeMutare = () =>
    section === "plantatie"
      ? moveMaterialeFromSantier()
      : moveMaterialeFromDepozit();

  const handleEliminaMaterial = (item) => {
    setOpenEliminare(true);
    setDesiredMaterial(item);
    setElimina(true);
  };

  const handleEliminaMaterialDa = (item) => {
    if (section === "plantatie") {
      moveMaterialeFromSantier();
      setOpenEliminare(false);
    } else {
      moveMaterialeFromDepozit();
      setOpenEliminare(false);
    }
  };

  const errorHandler = () => {
    if (quantity > desiredMaterial?.cantitate) {
      return true;
    }
    return false;
  };

  const moveMaterialFromDepozitToDepozit = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity,
      depozitId,
      depozitIdTo: selectedDepozit,
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/depozit/move-to-depozit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        enqueueSnackbar(`${desiredMaterial?.nume} a fost mutat.`, {
          variant: "success",
        });
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Depozit");
        enqueueSnackbar(`Eroare la mutare. Tip: Relogheaza-te!`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialFromDepozitToSantier = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity,
      depozitId,
      santierId: selectedSantier,
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/depozit/move-to-santier`,
        {
          credentials: "include",

          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        enqueueSnackbar(`${desiredMaterial?.nume} a fost mutat.`, {
          variant: "success",
        });
        setOpenMutare(false);
        setTriggerMutare(supplierData);
      } else {
        console.error("Failed to move to Depozit", supplierData);
        enqueueSnackbar(`Eroare la mutare. Tip: Relogheaza-te!`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialeFromDepozit = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity: elimina ? desiredMaterial.cantitate : quantity,
      depozitId,
      eliminare: elimina,
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/material/move-to-materiale-from-depozit`,
        {
          credentials: "include",

          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Santier");
        enqueueSnackbar(`${desiredMaterial?.nume} a fost mutat.`, {
          variant: "success",
        });
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Santier");
        enqueueSnackbar(`Eroare la mutare. Tip: Relogheaza-te!`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialFromSantierToDepozit = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity,
      depozitId: selectedDepozit,
      santierId: pathSegments[3],
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/santier/move-to-depozit`,
        {
          credentials: "include",

          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Depozit");
        enqueueSnackbar(`${desiredMaterial?.nume} a fost mutat.`, {
          variant: "success",
        });
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Depozit", supplierData);
        enqueueSnackbar(`Eroare la mutare. Tip: Relogheaza-te!`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialFromSantierToSantier = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity,
      santierId: depozitId,
      santierIdTo: selectedSantier,
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/santier/move-to-santier`,
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Depozit");
        enqueueSnackbar(`${desiredMaterial?.nume} a fost mutat.`, {
          variant: "success",
        });
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Depozit", supplierData);
        enqueueSnackbar(`Eroare la mutare. Tip: Relogheaza-te!`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const moveMaterialeFromSantier = async () => {
    const supplierData = {
      materialId: desiredMaterial.materialId,
      quantity: elimina ? desiredMaterial.cantitate : quantity,
      santierId: depozitId,
      eliminare: elimina,
    };

    try {
      const response = await fetch(
        `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/material/move-to-materiale-from-santier`,
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplierData),
        }
      );

      if (response.ok) {
        console.log("Material moved successfully to Santier");
        enqueueSnackbar(`${desiredMaterial?.nume} a fost mutat.`, {
          variant: "success",
        });
        setOpenMutare(false);
      } else {
        console.error("Failed to move to Santier");
        enqueueSnackbar(`Eroare la mutare. Tip: Relogheaza-te!`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const fetchData = useCallback(async (api, setter) => {
    try {
      const res = await fetch(api, {
        credentials: "include",
      });
      if (res.ok) {
        const data = await res.json();
        console.log("its me mario", data);
        setter(data);
      } else {
        console.error("Response not OK:", res);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(apiUrl, setData);
  }, [depozitId, apiUrl, openEliminare, openMutare, trigger, desiredMaterial]);

  useEffect(() => {
    const fetchSubData = async () => {
      try {
        const depozitsResponse = await fetch(
          "https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/depozit",
          {
            credentials: "include",
          }
        ).then((response) => {
          if (response.status === 401 || response.status === 403) {
            // Handle unauthorized or forbidden access
            throw new Error("Unauthorized or Forbidden");
          }
          if (!response.ok) {
            throw new Error("Failed to fetch depozits");
          }
          return response.json();
        });

        const santiersResponse = await fetch(
          "https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/santier",
          {
            credentials: "include",
          }
        ).then((response) => {
          if (response.status === 401 || response.status === 403) {
            // Handle unauthorized or forbidden access
            throw new Error("Unauthorized or Forbidden");
          }
          if (!response.ok) {
            throw new Error("Failed to fetch santiers");
          }
          return response.json();
        });

        setDepozits(depozitsResponse);
        setSantiers(santiersResponse);
      } catch (error) {
        console.error("Error fetching data:", error);

        if (error.message === "Unauthorized or Forbidden") {
          // Redirect to login or handle error appropriately
          alert("Session expired or access denied. Redirecting to login.");
          navigate("/login"); // Redirect to login page
        }
      }
    };

    fetchSubData();
  }, [openMutare, triggerPlantatie]);

  async function deleteMaterial() {
    let apiMaterial;

    if (section === "plantatie") {
      apiMaterial = `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/santier/${depozitId}/${desiredMaterial.materialId}`;
    } else {
      apiMaterial = `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/depozit/${depozitId}/${desiredMaterial.materialId}`;
    }

    const materialData = {
      quantity: desiredMaterial.cantitate,
      id: desiredMaterial._id,
      author: userData.name,
    };
    await fetch(apiMaterial, {
      credentials: "include",
      method: "DELETE",
      body: JSON.stringify(materialData),
      headers: {
        "Content-Type": "application/json",
      },
    });

    enqueueSnackbar(`${desiredMaterial?.nume} a fost eliminat.`, {
      variant: "error",
    });

    setDesiredMaterial(undefined);
  }

  function handleDeleteMaterial() {
    deleteMaterial();
    setOpenEliminare(false);
    setTrigger(desiredMaterial?.nume);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setDesiredMaterial(item); // Set current item here
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div key={depozitId} style={{ maxHeight: "40vh", overflowY: "auto" }}>
      <table
        className="table table-striped"
        style={{ marginTop: 20, borderSpacing: "50px" }}
      >
        <thead>
          <tr>
            <th style={{ paddingRight: "40px", paddingLeft: "30px" }}>Nume</th>
            <th style={{ paddingRight: "40px" }}>Producator</th>
            <th style={{ paddingRight: "40px" }}>Model</th>
            <th style={{ paddingRight: "40px" }}>Expira</th>
            <th style={{ paddingRight: "40px" }}>
              Cantitate {section === "depozit" ? "Depozit" : "Plantatie"}
            </th>
            <th>Actiuni</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={item._id}>
              <td
                style={{
                  paddingRight: "40px",
                  paddingLeft: "30px",
                  paddingTop: "10px",
                }}
              >
                {item.nume}
              </td>
              <td style={{ paddingRight: "40px", paddingTop: "10px" }}>
                {item.numeFirma}
              </td>
              <td style={{ paddingRight: "40px", paddingTop: "10px" }}>
                {item.model}
              </td>
              <td style={{ paddingRight: "40px", paddingTop: "10px" }}>
                {epochFormatDate(item?.dataExpirare)}
              </td>
              <td style={{ paddingRight: "40px", paddingTop: "10px" }}>
                {item.cantitate}
              </td>
              <td>
                <div>
                  <Button
                    id="composition-button"
                    aria-haspopup="true"
                    sx={{ color: "black" }}
                    onClick={(event) => handleClick(event, item)} // Pass item her
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        border: "1px solid black",
                        borderRadius: "12px",
                        backgroundColor: "white",
                        marginRight: "14px",
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </div>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    PaperProps={{
                      elevation: 0, // Removes the shadow
                      sx: {
                        overflow: "visible",
                        filter: "none", // Removes any filter effects like shadows or blurring
                        // Controls the margin to adjust the position as needed
                        "& .MuiAvatar-root": {
                          width: 42,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        borderRadius: "4px",
                        width: 100,
                        border: "1px solid #D9DCE1",
                      },
                    }}
                  >
                    <MenuItem
                      key={index + 1}
                      onClick={() => {
                        hanldeMutareDesired(desiredMaterial);
                        handleCloseMenu();
                      }}
                    >
                      Muta
                    </MenuItem>
                    <MenuItem
                      key={index + 2}
                      onClick={() => {
                        setOpenEliminare(true);
                        handleCloseMenu();
                      }}
                    >
                      Elimina
                    </MenuItem>
                  </Menu>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={openMutare}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div key={selectedButton}>
            <h2>{`Muta: ${desiredMaterial?.nume}`}</h2>
            <br />
            <ToggleButtonGroup
              value={selectedButton}
              exclusive
              onChange={handleButtonChange}
            >
              <ToggleButton value="depozit">Depozit</ToggleButton>
              <ToggleButton value="santier">Plantatie</ToggleButton>
              <ToggleButton value="materiale">Depozit prinicpal</ToggleButton>
            </ToggleButtonGroup>
            <br />
            <br />
            {selectedButton === "santier" ? (
              <>
                {" "}
                <div style={{ width: "100%", gap: "5px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Cantitate
                  </InputLabel>
                  <TextField
                    value={quantity}
                    onChange={handleQuantityChange}
                    error={errorHandler()}
                    helperText={errorHandler() ? "Stoc insuficient" : ""}
                    style={{ width: "30%", marginRight: "10px" }}
                  />

                  <Select
                    value={selectedSantier}
                    onChange={handleSantierChange}
                    placeholder="Santier"
                    style={{ width: "30%" }}
                  >
                    {santiers.map((material) => (
                      <MenuItem key={material._id} value={material._id}>
                        {material.nume} - {material.locatie}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    display: " flex",
                    marginTop: "10px",
                    marginRight: "60px",
                    justifyContent: "flex-end",
                  }}
                >
                  {section === "plantatie" ? (
                    <Button
                      onClick={() => moveMaterialFromSantierToSantier()}
                      variant="contained"
                    >
                      Muta in Plantatie
                    </Button>
                  ) : (
                    <Button
                      onClick={() => moveMaterialFromDepozitToSantier()}
                      variant="contained"
                    >
                      Muta in Plantatie
                    </Button>
                  )}
                </div>
              </>
            ) : selectedButton === "depozit" ? (
              <>
                <div style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Cantitate
                  </InputLabel>
                  <TextField
                    value={quantity}
                    onChange={handleQuantityChange}
                    error={errorHandler()}
                    helperText={errorHandler() ? "Stoc insuficient" : ""}
                    style={{ width: "30%", marginRight: "10px" }}
                  />

                  <Select
                    value={selectedDepozit}
                    onChange={(e) => handleDepozitChange(e)}
                    placeholder="Santier"
                    style={{ width: "30%" }}
                  >
                    {depozits.map((material) => (
                      <MenuItem key={material._id} value={material._id}>
                        {material.nume} - {material.locatie}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    display: " flex",
                    marginTop: "10px",
                    marginRight: "60px",
                    justifyContent: "flex-end",
                  }}
                >
                  {section === "plantatie" ? (
                    <Button
                      onClick={() => moveMaterialFromSantierToDepozit()}
                      variant="contained"
                    >
                      Muta in Depozit
                    </Button>
                  ) : (
                    <Button
                      onClick={() => moveMaterialFromDepozitToDepozit()}
                      variant="contained"
                    >
                      Muta in Depozit
                    </Button>
                  )}
                </div>
              </>
            ) : selectedButton === "materiale" ? (
              <>
                {" "}
                <div style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Cantitate
                  </InputLabel>
                  <TextField
                    value={quantity}
                    onChange={handleQuantityChange}
                    error={errorHandler()}
                    helperText={errorHandler() ? "Stoc insuficient" : ""}
                    style={{ width: "30%", marginRight: "10px" }}
                  />
                </div>
                <div
                  style={{
                    display: " flex",
                    marginTop: "10px",
                    marginRight: "60px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button onClick={handleMaterialeMutare} variant="contained">
                    Muta Materiale
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEliminare}
        onClose={() => setOpenEliminare(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <b>{desiredMaterial?.nume}</b>
            Sigur elimiati materialul?
            <br />
            <br />
            <span style={{ color: "grey" }}>
              {`
              Eliminandul, materialul va fi sters din ${
                section === "plantatie"
                  ? "plantatia actuala"
                  : "depozitul actual"
              } cat si
              din ${
                section === "plantatie"
                  ? "cantitate plantatie"
                  : "cantitate depozit"
              }  si stocul din depozitul principal.`}
              <br /> Daca doriti sa-l mutati inapoi in depozitul principal fara
              a afecta stocul global, folositi functia de mutare.
            </span>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ marginTop: "8px", marginRight: "12px" }}
                variant="contained"
                sx={{
                  bgcolor: "red.dark", // normal background color
                  "&:hover": {
                    bgcolor: "red.light", // hover background color
                  },
                }}
                onClick={handleDeleteMaterial}
              >
                Elimina
              </Button>

              <br />

              <Button
                style={{ marginTop: "8px" }}
                variant="contained"
                onClick={() => setOpenEliminare(false)}
              >
                Nu
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Record;
