import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/common/navbar";
import SoferiContainer from "./components/transport/soferi/SoferiContainer";
import MasiniContainer from "./components/transport/masini/MasiniContainer";
import SideMenu from "./components/common/SideMenu/SideMenu";
import SideMenuMobile from "./components/common/SideMenuMobile/SideMenuMobile";
import HomePage from "./components/common/HomePage";
import Furnizori from "./components/gestiune/furnizori/listFurnizori";
import Clienti from "./components/gestiune/clienti/listFurnizori";
import RecordList from "./components/clienti/recordList";
import Edit from "./components/clienti/edit";
import Create from "./components/clienti/create";
import Details from "./components/clienti/details";
import Record from "./components/gestiune/gestiunev3/tabs/tabDepozit";
import Record2 from "./components/gestiune/gestiunev3/tabs/tabSantier";
import Gestiune from "./components/gestiune/gestiunev3/listaGestiune";
import RecordAgro from "./components/gestiune/gestiuneEco/tabs/tabDepozit";
import RecordAgro2 from "./components/gestiune/gestiuneEco/tabs/tabSantier";
import GestiuneAgro from "./components/gestiune/gestiuneEco/listaGestiune";
import { useAuth } from "./components/common/Login/AuthContext";
import Login from "./components/common/Login/LoginPage";
import { useSideMenuContext } from "./components/common/SideMenuMobile/context/AuthContext";
import "./App.css";
import Signup from "./components/common/Login/SignupPage";

import ClientiContainer from "./components/transport/clienti/ClientiContainer";
import UserManagementContainer from "./components/common/UserManagemnt/UserManagementContainer";

import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./utils/theme/theme"; // Adjust the path as necessary

import AccountController from "./components/common/Account/AccountController";
import StatisticsPage from "./components/gestiune/gestiuneEco/charts/StatisticsPage";

import ListaVanzare from "./components/gestiune/vanzari/listaVanzare";
import InventarVanzare from "./components/gestiune/vanzari/inventarVanzare";
import ClientiVanzare from "./components/gestiune/vanzari/clienti/ClientiContainer";
import IstoricTabelVanzari from "./components/gestiune/vanzari/istoric/istoricVanzari";
import ListaComenzi from "./components/gestiune/vanzari/listaComenzi";
import DetaliiComanda from "./components/gestiune/vanzari/details/details";

import AngajatiPage from "./components/hr/angajatiPage";
import RecrutiPage from "./components/hr/recrutarePage";

function PrivateRoute({ children }) {
  const { isAuthenticated } = useAuth();
  // return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
}

const App = () => {
  const { mobileMenuOn } = useSideMenuContext();

  const [darkMode, setDarkMode] = useState(false); // State to toggle between themes

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: "#344955",
        }}
      >
        <Navbar />
        <div className="mobile">{mobileMenuOn && <SideMenuMobile />}</div>

        <div style={{ display: "flex", flex: 1 }}>
          <div className="desktop">
            <SideMenu setDarkMode={setDarkMode} darkMode={darkMode} />
          </div>

          <div
            style={{
              flex: 1,
              padding: 14,
              borderRadius: "12px 0px",
              backgroundColor: "white",
              overflowY: "auto",
              boxShadow: "inset 0px 0px 10px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/signup"
                element={
                  <PrivateRoute>
                    <Signup />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user-management"
                element={
                  <PrivateRoute>
                    <UserManagementContainer />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user-config"
                element={
                  <PrivateRoute>
                    <AccountController />
                  </PrivateRoute>
                }
              />

              {/* <Route path="/register" element={<RegisterPage />} /> */}

              <Route exact path="/" element={<HomePage />} />

              {/* Transport */}
              <Route
                path="/transport/masini"
                element={
                  <PrivateRoute>
                    <MasiniContainer />
                  </PrivateRoute>
                }
              />

              <Route
                path="/transport/soferi"
                element={
                  <PrivateRoute>
                    <SoferiContainer />
                  </PrivateRoute>
                }
              />

              <Route
                path="/transport/clienti"
                element={
                  <PrivateRoute>
                    <ClientiContainer />
                  </PrivateRoute>
                }
              />

              {/* Gestiune */}
              <Route
                path="/gestiune/materiale"
                element={
                  <PrivateRoute>
                    <Gestiune />
                  </PrivateRoute>
                }
              />
              <Route
                path="/gestiune/depozit/:id"
                element={
                  <PrivateRoute>
                    <Record />
                  </PrivateRoute>
                }
              />

              <Route
                path="/gestiune/santier/:id"
                element={
                  <PrivateRoute>
                    <Record2 />
                  </PrivateRoute>
                }
              />

              <Route
                path="/furnizori"
                element={
                  <PrivateRoute>
                    <Furnizori />
                  </PrivateRoute>
                }
              />
              <Route
                path="/gestiune/clienti"
                element={
                  <PrivateRoute>
                    <Clienti />
                  </PrivateRoute>
                }
              />

              {/*Agro*/}

              <Route
                path="/agronomie/materiale"
                element={
                  <PrivateRoute>
                    <GestiuneAgro />
                  </PrivateRoute>
                }
              />
              <Route
                path="/agronomie/depozit/:id"
                element={
                  <PrivateRoute>
                    <RecordAgro />
                  </PrivateRoute>
                }
              />

              <Route
                path="/agronomie/plantatie/:id"
                element={
                  <PrivateRoute>
                    <RecordAgro2 />
                  </PrivateRoute>
                }
              />

              <Route
                path="/agronomie/statistici"
                element={
                  <PrivateRoute>
                    <StatisticsPage />
                  </PrivateRoute>
                }
              />

              {/* Vanzari */}
              <Route
                exact
                path="/vanzari/lista"
                element={
                  <PrivateRoute>
                    <ListaVanzare />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/vanzari/comenzi"
                element={
                  <PrivateRoute>
                    <ListaComenzi />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/vanzari/comenzi/:id"
                element={
                  <PrivateRoute>
                    <DetaliiComanda />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vanzari/inventar"
                element={
                  <PrivateRoute>
                    <InventarVanzare />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vanzari/clienti"
                element={
                  <PrivateRoute>
                    <ClientiVanzare />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vanzari/istoric"
                element={
                  <PrivateRoute>
                    <IstoricTabelVanzari />
                  </PrivateRoute>
                }
              />

              <Route
                path="/hr/angajati"
                element={
                  <PrivateRoute>
                    <AngajatiPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="/hr/recrutare"
                element={
                  <PrivateRoute>
                    <RecrutiPage />
                  </PrivateRoute>
                }
              />

              {/* Clienti */}
              <Route
                exact
                path="/clienti/lista"
                element={
                  <PrivateRoute>
                    <RecordList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/clienti/edit/:id"
                element={
                  <PrivateRoute>
                    <Edit />
                  </PrivateRoute>
                }
              />
              <Route
                path="/clienti/create"
                element={
                  <PrivateRoute>
                    <Create />
                  </PrivateRoute>
                }
              />
              <Route
                path="/clienti/details/:id"
                element={
                  <PrivateRoute>
                    <Details />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
