import React, { useState, useEffect } from "react";
import { Button, Box, Modal } from "@mui/material";
import MaterialeSantier from "../tabelDepozit";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Factura from "../../../../utils/rapoarte/raportInventarPDF";
import { generateExcelFile } from "../rapoarte/raportXLSXPlantatii";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ExplicitIcon from "@mui/icons-material/Explicit";
import { useNavigate } from "react-router";
import { useDepozitContext } from "../context/AuthContext";
import { useAuth } from "../../../common/Login/AuthContext";
import { epochFormatDate } from "../../../../utils/formats/epochFormat";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const styleRaport = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Record = () => {
  const { id, section } = useParams();
  const navigate = useNavigate();
  const { setIsNewDepozit } = useDepozitContext();
  const { userData } = useAuth();

  const [openInchidePlantia, setInchidePlantatia] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);

  const apiUrl = `https://seal-app-g5l8g.ondigitalocean.app/api/agronomie/santier/${id}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(apiUrl, {
          credentials: "include",
        });
        if (res.ok) {
          const data = await res.json();
          setData(data);
        } else if (res.status === 401) {
          // Handle 401 Unauthorized
          alert("Access is forbidden Redirecting to login.");
          navigate("/login"); // Navigate to login page
        } else if (res.status === 403) {
          // Handle 403 Forbidden
          alert("Access is forbidden Redirecting to login.");
          navigate("/login"); // Navigate to login page
        } else {
          console.error("Response not OK:", res);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [apiUrl, id]); // Include `history` in the dependency array

  const dateSantier = `${data?.nume} - ${data?.locatie}`;

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const currentDate = getCurrentDate();

  const [openRaport, setOpenRaport] = useState(false);

  async function deleteSantier() {
    const dataMaterial = {
      materialeEliminate: data?.materialeSantier,
      depozitId: data.depozitTata,
      author: userData.name,
    };

    await fetch(apiUrl, {
      credentials: "include",
      method: "DELETE",
      body: JSON.stringify(dataMaterial),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  const handleDeleteSantier = () => {
    setIsNewDepozit(data);
    deleteSantier();
    navigate("/agronomie/materiale");
  };

  return (
    <div key={id}>
      <tr key={data?._id} onClick={() => setExpanded(!expanded)}>
        <td>
          <div style={{ marginLeft: "40px" }}>
            <h3>
              {data?.nume} - {data?.locatie}
            </h3>
            <p style={{ fontSize: "20px" }}>
              Materiale: {data?.materialeSantier?.length}
            </p>
          </div>
        </td>
        <td>
          {/* <Button variant="outlined" onClick={() => setOpen2(!open2)}>
            Editeaz Depozit
          </Button> */}
        </td>
      </tr>
      <MaterialeSantier depozitId={id} section={"plantatie"} />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setOpenRaport(true)}
          sx={{
            bgcolor: "blue.dark", // normal background color
            "&:hover": {
              bgcolor: "blue.light", // hover background color
            },
          }}
        >
          {" "}
          Genereaza raport
        </Button>
        <Button
          style={{ marginTop: "8px" }}
          variant="contained"
          sx={{
            bgcolor: "red.dark", // normal background color
            "&:hover": {
              bgcolor: "red.light", // hover background color
            },
          }}
          onClick={() => setInchidePlantatia(true)}
        >
          Inchide Plantatia
        </Button>
      </div>

      <Modal
        open={openInchidePlantia}
        onClose={() => setInchidePlantatia(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <b>
              {data?.nume} - {data?.locatie}
            </b>
            Sigur inchideti plantatia?
            <br />
            <br />
            <span style={{ color: "grey" }}>
              Inchizand plantatia, materialele din plantatie vor fi eliminate
              atat din plantatie cat si din cantitate plantatie si din stocul
              global din depozitul principal.
            </span>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ marginTop: "8px", marginRight: "12px" }}
                variant="contained"
                sx={{
                  bgcolor: "red.dark", // normal background color
                  "&:hover": {
                    bgcolor: "red.light", // hover background color
                  },
                }}
                onClick={handleDeleteSantier}
              >
                Inchide
              </Button>

              <br />

              <Button
                style={{ marginTop: "8px" }}
                variant="contained"
                onClick={() => setInchidePlantatia(false)}
                sx={{
                  bgcolor: "blue.dark", // normal background color
                  "&:hover": {
                    bgcolor: "blue.light", // hover background color
                  },
                }}
              >
                Nu
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openRaport}
        onClose={() => setOpenRaport(!openRaport)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleRaport}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>Genereaza raport</h2>
            <br />
            <PDFDownloadLink
              document={
                <Factura
                  materiale={data?.materialeSantier}
                  istoric={data?.istoric}
                  section={section}
                  data={data}
                  date={currentDate}
                />
              }
              fileName={`${"Raport "}${data.nume}${" "}${
                data.locatie
              }${".pdf"}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Generating PDF..."
                ) : (
                  <Button variant="contained" color="error">
                    Raport .PDF <PictureAsPdfIcon style={{ marginLeft: 12 }} />
                  </Button>
                )
              }
            </PDFDownloadLink>{" "}
            <br />
            <div>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  generateExcelFile(data, dateSantier, currentDate)
                }
              >
                Raport .XLSX <ExplicitIcon style={{ marginLeft: 12 }} />
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Record;
